<template>
  <div
    class="home"
    v-bind:style="{
      'background-image':
        'linear-gradient(to right, #000000, #ffffff00), ' +
        'url(' +
        changeBackground +
        ')',
    }"
  >
    <v-container fill-height>
      <v-row>
        <v-col md="12" lg="6">
          <h2 class="heading">
            Facultad de <br />
            Economía y Negocios
          </h2>
          <hr/>
          <v-row>
            <v-col cols="4">
              <v-btn
                class="ma-2"
                depressed
                :disabled="btnStatus"
                large
                dark
                block
                color="primary"
                href="https://home.inscribefen.com/"
                >Campus Norte</v-btn
              >
            </v-col>
            <v-col cols="4">
              <v-btn
                class="ma-2"
                depressed
                large
                dark
                block
                color="primary"
                @click="confirmCampus(2)"
                >Campus Sur</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      backgroundImage: require("@/assets/portada.png"),
      campusId: 0,
      btnStatus:null,
    
      
            
    };
  },
  computed: {
    changeBackground() {
      let backgroundImage = require("@/assets/portada.png");

      if (this.campusId === 1) {
        backgroundImage = require("@/assets/norte1.png");        
        
      } else {
        if (this.campusId === 2) {
          backgroundImage = require("@/assets/sur1.png");
        }
      } 
      return backgroundImage;
    },
    getCampus()
    {
      return this.campusId;
    },
  },
  methods: {
    confirmCampus(campus) {
      this.campusId = campus;
      this.$emit("confirmCampus", campus);      
    }
  }
};
</script>

<style scoped>
.home {
  height: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.heading {
  color: white;
  font-size: 4em;
}

.body {
  color: white;
  font-size: 1.5em;
}
</style>
